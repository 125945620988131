import React from 'react';
import { app_name } from '../constants/endpoints';
import { connect } from 'react-redux';
// @import styles
import '../../css/containers/contact.scss';

const Contact = props => {
  let appTitle = app_name === 'onderbouwdonline' ? 'Onderbouwd Online' : 'Kleuterwijzer'
  return (
    <div className="mainContainer">
      <section className="pageContainer contactWrapper">
        <div className="contentSec">
          {props.userData.userRole !== 'parent' ? (
            <>
              <h2>Contact</h2>
              <p>
                Heb je een vraag over het gebruik van {appTitle}, mail je
                vraag dan naar{' '}
                <a href="mailto:helpdesk@klassewerk.com">
                  helpdesk@klassewerk.com
                </a>
                .
              </p>
              <br />
              <br />
              <h2>Over {appTitle}</h2>
              <p>
                {appTitle} is een uitgave van Klassewerk
                Onderwijstraining
                <br />
                <br />
                Parkweg 9, 8191 JK Wapenveld
                <br />
              </p>
            </>
          ) : (
            <>
              <h2>Contact</h2>
              <p>
                {appTitle} is een uitgave van Klassewerk
                Onderwijstraining. Voor ondersteuning bij het gebruik van
                {appTitle} of voor vragen over de ontwikkeling van uw
                kind kunt u contact opnemen met de leerkracht of beheerder van de
                school.
              </p>
            </>
          )}
        </div>
      </section>
    </div>
  );
};

const mapStateToProps = state => ({
  userData: state.initialData.userData
});

export default connect(
  mapStateToProps,
  null
)(Contact);
